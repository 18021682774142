@import '~highlight.js/styles/xcode.css';
/* 全局公共样式文件，单页面特定的样式请定义在对应页面附带的样式表文件中 */
#splash-screen {
  min-height: 100%;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #4F5E93;
  }

  .splash-screen-bars {
    width: 135px;
    height: 140px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -60px;
    margin-left: -40px;
    background: url("assets/images/bars.svg");
  }
}

.to-edge {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.full-screen {
  position: fixed;
  .to-edge
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track-piece {
  background: rgba(185, 175, 175, 0.2);
}

::-webkit-scrollbar-thumb {
  background-color: rgba(114, 118, 135, 0.5);
  border-radius: 5px;

  &:hover {
    background-color: rgba(114, 118, 135, 8);
  }
}

#main-content {
  display: flex;
  position: relative;

  #page-container {
    width: 100%;
    display: block;
    overflow-y: auto;
  }
}

.standard-list-page {
  padding: 4em 0 1em 0;

  .title-images {
    width: 35px;
    height: 35px;
    margin-top: 10px;
  }

  .title-bar {
    top: 0;
    z-index: 10;
    width: 100%;
    position: absolute;
    padding: 0.5em 1em;
    background-color: #F2F5FA;

    nz-breadcrumb {
      display: inline-block;
      font-size: 18px;
      font-weight: bold;
      margin: 1em 0;
      //border-left: 5px solid #4F5E93;
      vertical-align: top;
      line-height: 1.1;
      text-indent: 0.5em;

      nz-breadcrumb-item {
        a {
          color: #000000;

          &:hover {
            color: #2D8CF0;
          }
        }
      }
    }

    h3 {
      display: inline-block;
      font-size: 18px;
      margin: 1em 0;
      //border-left: 5px solid #4F5E93;
      vertical-align: top;
      line-height: 1.1;
      font-weight: bold;
      text-indent: 0.5em;
    }

    .action-buttons {
      display: block;
      float: right;
      margin: 1em 0.5em;

      nz-tree-select {
        margin-right: 10px;
      }

      nz-select {
        margin-right: 10px;
        width: 250px;
      }

      button {
        border-radius: 0;

        &:not(:last-child) {
          margin-right: 0.3em;
        }
      }

      .ant-btn-primary {
        background-color: #405BBB;
        border-color: #405BBB;
      }
    }
  }

  .content-area {
    margin: 1.5em 1em;
    border-radius: 0.5em;
    background-color: #fff;

    &.action-bar {
      padding: 1em;

      nz-select {
        width: 174px;
      }

      nz-input-group {
        .ant-input-group-addon {
          width: 80px;
        }
      }

      form {
        display: contents;
      }

      .search-input-md {
        width: 200px;
      }

      .inline-condition {
        display: inline-block;
        width: auto;
        min-width: 210px;
        margin-bottom: 10px;

        &:not(:last-child) {
          margin-right: 1rem;
        }

        nz-cascader, nz-tree-select {
          width: 173px;
        }
      }

      .block-condition {
        display: block;
      }

      .filter-switch-btn {
        float: right;
        margin-left: 10px;
      }

      .ant-btn-primary {
        background-color: #405BBB;
        border-color: #405BBB;
      }

      svsp-collapse {
        &:not(:first-child) .advanced-filter {
          padding-top: 0.5rem;
        }
      }

      .advanced-filter {
        padding-right: 8rem;
        position: relative;

        .conditions-row {
          &:not(:first-child) {
            margin-top: 0.5rem;
          }
        }

        .apply-filter-btn {
          position: absolute;
          right: 0;
          bottom: 10px;
          width: 6.8rem;
        }
      }

      .ant-cascader-input {
        width: 184px;
      }

      nz-select {
        width: 184px;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    .ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: #405BBB;
        border-color: #405BBB;
      }
    }

    .ant-checkbox-indeterminate .ant-checkbox-inner::after {
      background-color: #405BBB;
    }

    .ant-tabs-nav {
      margin: 0;
    }

    .info-alert {
      padding: 10px 10px 0 10px;
    }

    .ant-alert-info {
      background-color: #F5F7FF;
      border: 1px solid #405BBB;
      padding: 4px 10px;
    }

    .ant-alert-info .ant-alert-icon {
      color: #405BBB;
    }

    .ant-alert-message {
      color: #405BBB;
    }

    .columns-btn {
      padding: 10px;
      height: 40px;

      .setting-th {
        float: right;
        margin-bottom: 10px;
        background-color: #405BBB;
        border-color: #405BBB;
      }

      button {
        &:not(:first-child) {
          margin-right: 20px;
        }
      }
    }

    nz-table {
      thead {
        tr {
          th:first-child {
            padding-left: 20px;
          }

          .ant-table-cell-fix-right-first {
            padding-left: 12px !important;
            text-align: center;
          }
        }
      }

      tbody {
        tr {
          td:first-child {
            padding-left: 20px;
          }

          .ant-table-cell-fix-right-first {
            padding-left: 12px !important;
          }
        }
      }
    }
  }

}

.text-alignment {
  color: #000;
  //text-align-last: justify;
  //text-align: justify;
  //text-justify: distribute-all-lines;
  text-align: left;
  padding-right: 20px;

  &:after {
    content: "：";
    position: absolute;
  }
}

.tangram-suggestion-main {
  z-index: 1000;
}

.show-original-picture {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 10000;

  img {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 10000;
    margin: auto;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
}

.table-img-size {
  width: 60px;
  height: 60px;
  object-fit: cover
}

.aggregate-data {
  text-align: right;
  margin-bottom: 10px;

  nz-select {
    width: 150px;
  }
}

.search-area {
  display: flex;
  display: -webkit-flex;
  flex-wrap: nowrap;
}

.search-area-input {
  width: 90%;
}

.search-area-button {
  display: inline-flex;
  justify-content: flex-end;
  width: 200px;
  margin-right: 0;

  button {
    margin-left: 10px;
    margin-right: 0;
  }
}

.cdk-overlay-pane {
  .ant-dropdown {
    .ant-dropdown-menu {
      .ant-dropdown-menu-item {
        .ant-checkbox-wrapper {
          .ant-checkbox-checked {
            .ant-checkbox-inner {
              background-color: #405BBB;
              border-color: #405BBB;
            }
          }
        }
      }
    }
  }
}

.ant-modal-body {
  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: #405BBB;
      border-color: #405BBB;
    }
  }

  .ant-checkbox-disabled {
    .ant-checkbox-inner {
      background-color: #F5F5F5;
    }
  }

  .ant-btn-primary {
    background-color: #405BBB;
    border-color: #405BBB;
  }

  .ant-tabs-tab-active {
    .ant-tabs-tab-btn {
      color: #405BBB !important;
      padding: 0 20px;
    }
  }

  .ant-tabs-tab-btn {
    padding: 0 20px;
  }

  .ant-tabs-ink-bar {
    background-color: #405BBB;
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: #405BBB;

    &::after {
      background-color: #405BBB;
    }
  }

  .ant-radio-disabled .ant-radio-inner {

    &::after {
      background-color: #C4C4C4;
    }
  }
}

.ant-modal-footer {
  .ant-btn-primary {
    background-color: #405BBB;
    border-color: #405BBB;
  }
}

.table-third-icon {
  width: 40px;
  height: 40px;
  object-fit: cover;
  margin: 0 auto;
  display: block;
}
